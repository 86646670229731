import { USER_ACTION_TYPES } from './types';

const INITIAL_STATE = {
  currentUser: null,
  allUsers: [],
  isLoading: false,
  error: null,
  signinError: null,
  adminDashboard: null,
  isDashboadLoding: false,
};

export const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
    
  switch (type) {
    case USER_ACTION_TYPES.SIGN_IN_SUCCESS:
      return { ...state, currentUser: payload, signinError:null };
    case USER_ACTION_TYPES.SIGN_IN_FAILED:
       return { ...state, signinError: payload };

    case USER_ACTION_TYPES.SIGN_OUT_SUCCESS:
      return { ...state, currentUser: null };
    case USER_ACTION_TYPES.SIGN_OUT_FAILED:
    case USER_ACTION_TYPES.SIGN_UP_FAILED:
      return { ...state, error: payload };

    case USER_ACTION_TYPES.FETCH_USERS_START:
      return { ...state, isLoading: true };
    case USER_ACTION_TYPES.FETCH_USERS_SUCCESS:
      return { ...state, allUsers: payload, isLoading: false };
    case USER_ACTION_TYPES.FETCH_USERS_FAILED:
      return { ...state, error: payload, isLoading: false };

    case USER_ACTION_TYPES.USER_EDIT_START:
      return { ...state, isLoading: false };
    case USER_ACTION_TYPES.USER_EDIT_SUCCESS:
      return { ...state, currentUser:payload, isLoading: false };
    case USER_ACTION_TYPES.USER_EDIT_FAILED:

    case USER_ACTION_TYPES.FETCH_DASHBOARD_START:
      return { ...state, isDashboadLoding: false };
    case USER_ACTION_TYPES.FETCH_DASHBOARD_SUCCESS:
      return { ...state, adminDashboard:payload, isDashboadLoding: false };
    case USER_ACTION_TYPES.FETCH_DASHBOARD_FAILED:


    default:
      return state;
  }
};