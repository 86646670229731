import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import DrawerAppBar from './Header';
import { signUpStart, getSnapshotFromUserAuth } from '../../store/user/action';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(25),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleButton: {
    marginTop: theme.spacing(3),
  },
  separator: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
  },
  line: {
    flexGrow: 1,
    height: 1,
    backgroundColor: '#ccc',
  },
  centeredGoogleButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user.currentUser);

  const signupClicked = () => {
    dispatch(signUpStart(firstname, lastname, email, password));
  };

  const handleGoogleSuccess = (response) => {
    fetch('http://localhost:8000/auth-return/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: response.credential,
      }),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 'success') {
        const redirectUrl = localStorage.getItem('redirectUrl') || '/';
        localStorage.setItem("token", JSON.stringify(data));
        localStorage.removeItem('redirectUrl');
        dispatch(getSnapshotFromUserAuth());
        navigate(redirectUrl);
      } else {
        console.error('Error signing up with Google:', data.message);
      }
    })
    .catch((error) => console.error('Error:', error));
  };

  const handleGoogleError = (error) => {
    console.error('Google Sign-Up Error:', error);
  };

  useEffect(() => {
    if (currentUser) {
      const redirectUrl = localStorage.getItem('redirectUrl') || '/';
      localStorage.removeItem('redirectUrl');
      navigate(redirectUrl);
    }
  }, [currentUser, navigate]);

  return (
    <GoogleOAuthProvider clientId="182767965221-25lq6c03vknceephnqbbjgm8r8dh7h2q.apps.googleusercontent.com">
      <Container component="main" maxWidth="xs">
        <DrawerAppBar />
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <FollowTheSignsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstname}
                  onChange={(evt) => setFirstname(evt.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={lastname}
                  onChange={(evt) => setLastname(evt.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(evt) => setPassword(evt.target.value)}
                />
              </Grid>
              <Grid item xs={12} justifyContent="flex-end">
                Already have an account?
                <Link href="/signin" variant="body2">
                  {" Sign in"}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={signupClicked}
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>

            <div className={classes.separator}>
              <div className={classes.line}></div>
              <Typography variant="body2" style={{ margin: '0 8px', color: '#888' }}>OR</Typography>
              <div className={classes.line}></div>
            </div>

            <div className={classes.centeredGoogleButton}>
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleError}
                theme="filled_blue"
                shape="pill"
                size="large"
                text="log in with google"
              />
            </div>

          </form>
        </div>
      </Container>
    </GoogleOAuthProvider>
  );
}
