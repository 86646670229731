import { USER_ACTION_TYPES } from './types';
import { createAction } from '../../utils/reducer.utils';

export const setCurrentUser = (user) =>
  createAction(USER_ACTION_TYPES.SET_CURRENT_USER, user);

export const checkUserSession = () =>
  createAction(USER_ACTION_TYPES.CHECK_USER_SESSION);

export const getSnapshotFromUserAuth = () =>
  createAction(USER_ACTION_TYPES.GET_SNAPSHOT_FROM_USER_AUTH); // Add this line
  
export const googleSignInStart = () =>
  createAction(USER_ACTION_TYPES.GOOGLE_SIGN_IN_START);

export const emailSignInStart = (email, password) =>
  createAction(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, { email, password });

export const emailSubscribed = (email) =>
  createAction(USER_ACTION_TYPES.EMAIL_SUBSCRIBED_START, { email });

export const signInSuccess = (user) =>
  createAction(USER_ACTION_TYPES.SIGN_IN_SUCCESS, user);
export const signInFailed = (error) =>
  createAction(USER_ACTION_TYPES.SIGN_IN_FAILED, error);
export const signUpStart = (firstname, lastname, email, password) =>
  createAction(USER_ACTION_TYPES.SIGN_UP_START, {
    firstname, 
    lastname, 
    email, 
    password
  });
export const signUpSuccess = (user, additionalDetails) =>
  createAction(USER_ACTION_TYPES.SIGN_UP_SUCCESS, { user, additionalDetails });
export const signUpFailed = (error) =>
  createAction(USER_ACTION_TYPES.SIGN_UP_FAILED, error);

export const userEditStart = (id, firstName, lastName, email, mobileNo) =>
  createAction(USER_ACTION_TYPES.USER_EDIT_START, {
    id,
    firstName, 
    lastName, 
    email, 
    mobileNo    
  });
export const userEditSuccess = ( user ) =>
  createAction(USER_ACTION_TYPES.USER_EDIT_SUCCESS, user);
export const userEditFailed = (error) =>
  createAction(USER_ACTION_TYPES.USER_EDIT_FAILED, error);

export const signOutStart = () =>
  createAction(USER_ACTION_TYPES.SIGN_OUT_START);
export const signOutSuccess = () =>
  createAction(USER_ACTION_TYPES.SIGN_OUT_SUCCESS);
export const signOutFailed = (error) =>
  createAction(USER_ACTION_TYPES.SIGN_OUT_FAILED, error);

export const fetchUsersStart = () =>
  createAction(USER_ACTION_TYPES.FETCH_USERS_START);
export const fetchUsersSuccess = (useresArray) =>
  createAction(USER_ACTION_TYPES.FETCH_USERS_SUCCESS, useresArray);
export const fetchUsersFailed = (error) =>
  createAction(USER_ACTION_TYPES.FETCH_USERS_FAILED, error);

export const fetchDashboardStart = () =>
  createAction(USER_ACTION_TYPES.FETCH_DASHBOARD_START);
export const fetchDashboardSuccess = (useresArray) =>
  createAction(USER_ACTION_TYPES.FETCH_DASHBOARD_SUCCESS, useresArray);
export const fetchDashboardFailed = (error) =>
  createAction(USER_ACTION_TYPES.FETCH_DASHBOARD_FAILED, error);