import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollRestoration = () => {
  const prevLocation = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (prevLocation.current !== location.pathname) {
      window.scrollTo(0, 0);
    }
    prevLocation.current = location.pathname;
  }, [location]);

  return null;
};

export default useScrollRestoration;
