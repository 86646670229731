import { createTheme } from '@mui/material/styles';

// #2d3b8d = dark
// #2779c2 = medium
// #05aced = light

// const arcBlue = "#2d3b8d";
// const arcOrange = "#2779c2";
// const arcGrey = "#05aced";
// const light = "#A0DFF8"

const arcBlue = "#004D49";
const arcOrange = "#068466";
const arcGrey = "#ABCC76";
const light = "#E6F2E0"

export default createTheme({
palette: {
    common: {
      main: arcBlue,
      secondary: arcOrange,
      last: arcGrey,
      light: light
    },
    primary: {
      main: arcBlue
    },
    secondary: {
      main: arcOrange
    }
  },
    typography: {
    tab: {
      fontFamily: "Raleway",
      textTransform: "none",
      fontWeight: 700,
      color: "white",
      fontSize: "1rem"
    },
    estimate: {
      fontFamily: "Pacifico",
      fontSize: "1rem",
      textTransform: "none",
      color: "white"
    },
    h1: {
      fontFamily: "Arial",
      fontWeight: 800,
      fontSize: "5rem",
      color: arcBlue,
      lineHeight: 1.8
    },
    h2: {
      fontFamily: "Arial",
      fontWeight: 600,
      fontSize: "1.7rem",
      color: 'black',
      lineHeight: 1.5
    },
    h3: {
      fontFamily: "Arial",
      fontSize: "1.3rem",
      color: 'black',
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Arial",
      fontSize: "1.75rem",
      color: arcBlue,
      fontWeight: 700
    },
    h5: {
      fontFamily: "Arial",
      fontSize: "1.5rem",
      color: arcBlue,
      fontWeight: 600
    },
    
    h6: {
      fontWeight: 700,
      fontSize: "1.2rem",
      fontFamily: "Arial",
      color: arcBlue
    },
    h7: {
      fontWeight: 800,
      fontSize: "1rem",
      fontFamily: "Arial",
      color: arcBlue
    },
    subtitle1: {
      fontFamily: "Arial",
      fontSize: "1.2rem",
      fontWeight: 550,
      color: "black",
    },
    subtitle2: {
      fontFamily: "Arial",
      fontSize: "1.2rem",
      fontWeight: 300,
      color: arcGrey
    },
    subtitle3: {
      fontFamily: "Arial",
      fontSize: "1.25rem",
      fontWeight: 300,
      color: arcGrey
    },
    body1: {
      fontFamily: "Georgia",
      fontSize: "1.25rem",
      fontWeight: 400,
      color: "black",
    },
    body2: {
      fontFamily: "Georgia",
      fontSize: "1.2rem",
      fontWeight: 400
    },
    caption: {
      fontFamily: "Arial",
      fontSize: "1rem",
      fontWeight: 300,
      color: arcGrey
    },
    learnButton: {
      borderColor: arcBlue,
      borderWidth: 2,
      textTransform: "none",
      color: arcBlue,
      borderRadius: 50,
      fontFamily: "Roboto",
      fontWeight: "bold"
    }
  }
});