import React, {useEffect} from 'react';
import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SectionsHeader from './components/SectionsHeader'
import flux_install from '../ui/cogvideo/CogVideoX.png';

import BlogIntro from './components/BlogIntroSections' 

import backg from '../ui/ssss.png';
import maino from '../ui/workflow.png';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container1: {
    backgroundImage: `url(${backg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  dividerStyle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column', // Arrange elements in a column
    alignItems: 'flex-start', // Align text to the left
    padding: '20px',
  },
  lineStyle: {
    width: '100%',
    height: '1.5px', // Height of the horizontal line
    backgroundColor: '#004D49', // Change this to your desired line color
    marginBottom: '0px', // Spacing between line and text
  },
}));

const AiWorkflows = () => {
  const classes = useStyles();
  
  useEffect(() => {
    document.title = 'Workflows - Deep Dive into AI Workflows';
  }, []);

  return (
    <Grid>

      <Grid item xs={12}>
        <SectionsHeader
          header="Deep Dive into AI Workflows"
          subheading="Explore detailed processes and expert-level workflows to achieve precision and creativity in AI-driven projects."
          image={maino}
          altText="Alternative Text"
        />
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>
      
      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="How to Install CogVideoX in ComfyUI"
            description="CogVideoX Text-to-Video and Image-to-Video Generation"
            imageUrl={flux_install}
            altText="Stable Diffusion install guide"
            url_link="/workflows/install-cogvideox-comfyui"
          />
        </Box>
      </Grid>

    </Grid>
  );
};

export default AiWorkflows;
