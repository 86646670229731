import * as React from 'react';
import { useRef, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import logo from '../ui/logoll-1.png';
import logo from '../ui/logo-2.png';
import { makeStyles } from '@mui/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Tab from '@mui/material/Tab';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Stack from '@mui/material/Stack';

import { signOutStart } from '../../store/user/action';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser } from '../../store/user/selector';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { emailSubscribed } from "../../store/user/action";

const drawerWidth = 240;

const navItems = [
    { name: "Home", link: "/", activeIndex: 0 },
    { name: "Stable Diffusion", link: "/stable-diffusion",activeIndex: 1 },
    { name: "Tutorials", link: "/tutorials", activeIndex: 2 },
    { name: "Workflows", link: "/workflows", activeIndex: 3 },
    { name: "Earning Ideas", link: "/earning-ideas",activeIndex: 4 },
    { name: "Blog", link: "/blog", activeIndex: 5 },
  ];

const settings = [
    { name: "Profile", link: "/user/profile", activeIndex: 0 },
    { name: "Admin Pannel", link: "/admin", activeIndex: 2 },
    { name: "New Bookings", link: "/admin/new-booking",activeIndex: 1 },
  ];

const user_settings = [
    { name: "Profile", link: "/user/profile", activeIndex: 0 },
    { name: "Booking", link: "/user/booking", activeIndex: 2 },
  ];

const useStyles = makeStyles(theme => ({
  logo: {
    height: "5em",
    [theme.breakpoints.down("md")]: {
      height: "4em"    
    },
    [theme.breakpoints.down("xs")]: {
      height: "3.5em"
    }
  },
  '@media (max-width: 1064px)': {
    tabContainer: {
      display: 'none', // This will hide the tab container for screens less than 1064px
    },
  },
  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  tab: {
    minWidth: 20,
    '&:hover': {
    color: '#004D49',
    opacity: 1,
    },

  },
  tabBook: {
    minWidth: 20,
    marginLeft: "45px",

    '&:hover': {
    color: '#004D49',
    opacity: 1,
  },
  },
  tabContainer: {
    marginLeft: "auto"
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  drawerIcon: {
    height: "50px",
    width: "50px"
  },
  button: {
    marginLeft: "20px",
    marginRight: "10px",
    [theme.breakpoints.down("md")]: {
       marginLeft: "auto" 
     
    },
    [theme.breakpoints.down("xs")]: {
       marginLeft: "auto"
    },
  signbu: {
    [theme.breakpoints.down("sm")]: {
      height: 20,
      width: 30,
    },
    [theme.breakpoints.down("md")]: {
      height: 20,
      width: 30,
    },
  }
  },
  }));


function DrawerAppBar(props) {
  const classes = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const currentUser = useSelector(selectCurrentUser);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false); // State to control the modal
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handletabValue = (e, newValue) => {
    setTabValue(newValue);
  }

  const location = useLocation(); // React Router's useLocation hook

  useEffect(() => {
    // Set active tab based on the current pathname
    const { pathname } = location;
    if (pathname === '/') {
      setTabValue(0);
    } else if (pathname.startsWith('/stable-diffusion')) {
      setTabValue(2);
    } else if (pathname.startsWith('/tutorials')) {
      setTabValue(4);
    } else if (pathname.startsWith('/workflows')) {
      setTabValue(6);
    } else if (pathname.startsWith('/earning-ideas')) {
      setTabValue(8);
    } else if (pathname.startsWith('/blog')) {
      setTabValue(10);
    } else {
      setTabValue(0); // Set default tab value if the path doesn't match any
    }
  }, [location]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogOut = () => {
    setAnchorElUser(null);
    dispatch(signOutStart())
    // navigate("/");    
  };

  const SubscribeClicked = (e) => {
    setOpen(false);
    e.preventDefault();
    dispatch(emailSubscribed(email));
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Button
              component={Link}
              to="/"
              disableRipple
              sx={{display: { xs: 'block', sm: 'block' } }}
              className={classes.logoContainer}
              
            >
              <img alt="company logo" className={classes.logo} src={logo} />
            </Button>
      <Divider />
      <List>
        {navItems.map((page, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton component={Link} to={page.link} sx={{ textAlign: 'center' }}>
              <ListItemText color="main" primary={page.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const trigger = useScrollTrigger({disableHysteresis: true, threshold: 10});

  const footerRef = useRef(null);

  const scrollToFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed"  component="nav" 
        elevation={trigger ? 1 : 0}
        style={{
          backgroundColor: trigger ? "#ffff" : "transparent",
          boxShadow: trigger
            ? "5px 0px 27px -5px rgba(0, 0, 0, 0.3) !important"
            : undefined
        }}>

        <Toolbar>          
          <Button
              component={Link}
              to="/"
              size="small"
              disableRipple
              className={classes.logoContainer}
            >
              <img alt="company logo" className={classes.logo} src={logo} />
            </Button>

          <Tabs
          sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' } }}
            value={tabValue}
            onChange={handletabValue}
            className={classes.tabContainer}
            indicatorColor="primary"
            center="true"
          >
              <Tab
                className={classes.tab}
                component={Link}
                to='/'
                label="Home"
              />
            <Divider orientation="vertical" variant="middle" flexItem />
            <Tab
                className={classes.tab}
                component={Link}
                to='/stable-diffusion'
                label="Stable Diffusion"
              />
            <Divider orientation="vertical" variant="middle" flexItem />
            <Tab
                className={classes.tab}
                component={Link}
                to="/tutorials"
                label="Tutorials"
              />
            <Divider orientation="vertical" variant="middle" flexItem />
            <Tab
                className={classes.tab}
                component={Link}
                to="/workflows"
                label="Workflows"
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <Tab
                className={classes.tab}
                component={Link}
                to='/earning-ideas'
                label="Earning Ideas"
              />
             <Divider orientation="vertical" variant="middle" flexItem />
              <Tab
                className={classes.tab}
                component={Link}
                to="/blog"
                label="Blog"
              />
             <Divider orientation="vertical" variant="middle" flexItem />
          </Tabs>


          {currentUser ? (
            <Box sx={{ flexGrow: 0 }} className={classes.button}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} >
                  <Avatar alt="Remy Sharp" src="/static/default.png" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                
                <MenuItem onClick={handleLogOut} >
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
              ) : (
              <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center" className={classes.button}>
                <Button
                  variant="contained"
                  sx={{
                    height: matchesMD ? 30 : 35,
                    width: matchesMD ? 90 : 120,
                    borderRadius: matchesMD ? 10 : 28,
                    fontSize: matchesMD ? '0.5rem' : '0.8rem',
                  }}
                  color="primary"
                  onClick={handleClickOpen}
                >
                  Subscribe
                </Button>
              </Stack>
            )}
          

          <IconButton
            color="primary"
            className={classes.drawerIconContainer}
            onClick={handleDrawerToggle}
            disableRipple
            sx={{mr: 2, display: { md: 'none' }}}
          >
            <MenuIcon className={classes.drawerIcon} />
          </IconButton>




        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor='right'
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md:'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Dialog 
        open={open} 
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: '#E6F2E0', // Light green background
            color: 'black', // Text color
            borderRadius: '10px', // Rounded corners
            padding: '20px', // Padding inside the dialog
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          <Typography variant="h3" sx={{fontSize: matchesMD ? "1.2rem" : "1.32rem", letterSpacing: matchesMD ? "0.4px" : "0.6px", lineHeight:matchesMD ? "31px" : "35px"}} gutterBottom>
            Subscribe to our Newsletter
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '20px', textAlign: 'center' }}>
            Enter your email address below to receive updates and news from us.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={ evt => setEmail(evt.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black', // Outline color
                },
                '&:hover fieldset': {
                  borderColor: 'darkgreen', // Hover outline color
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button size="large" onClick={SubscribeClicked} variant="contained" color="primary" sx={{ backgroundColor: '#004D49', color: 'white' }}>
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>


    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;