import React, {useEffect} from 'react';
import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import SectionsHeader from './components/SectionsHeader'
import maino from '../ui/earnigl.png';
import m_stock_image from '../ui/Designer (7).jpeg';
import m_stock_image1 from '../ui/cat.jpeg';

import BlogIntro from './components/BlogIntroSections' 
import backg from '../ui/ssss.png';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container1: {
    backgroundImage: `url(${backg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  dividerStyle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column', // Arrange elements in a column
    alignItems: 'flex-start', // Align text to the left
    padding: '20px',
  },
  lineStyle: {
    width: '100%',
    height: '1.5px', // Height of the horizontal line
    backgroundColor: '#004D49', // Change this to your desired line color
    marginBottom: '0px', // Spacing between line and text
  },
}));

const AiEarning = () => {
  const classes = useStyles();
  useEffect(() => {
    document.title = 'Ways to Make Money Online Using AI';
  }, []);

  return (
    <Grid>

      <Grid item xs={12}>
        <SectionsHeader
          header="AI for Income: Monetize Your Skills"
          subheading="Discover innovative ways to generate income through AI-powered services, product development, and content creation."
          image={maino}
          altText="Alternative Text"
        />
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>


      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="Create A Faceless Youtube Channel For AI Animated Stories"
            description="AI Animated Story: Choosing the Right Youtube Niche for Success"
            imageUrl={m_stock_image1}
            altText="Alternative Text"
            url_link="/earning-ideas/create-a-faceless-youtube-channel-for-ai-animated-stories"
          />
        </Box>
      </Grid>
      

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="Monetize Your Imagination: Make Money Online with AI-Powered Stock Images"
            description="Automate Your Art: Generate and Sell Images Effortlessly with AI."
            imageUrl={m_stock_image}
            altText="Alternative Text"
            url_link="/earning-ideas/how-to-make-money-using-stock-images-ai"
          />
        </Box>
      </Grid>
      

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>

    </Grid>
  );
};

export default AiEarning;
