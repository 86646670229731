import React from 'react';
import { Grid, Typography, Box, LinearProgress, IconButton, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InsightsIcon from '@mui/icons-material/Insights';
import SchoolIcon from '@mui/icons-material/School';
import backg from '../../ui/ssss.png';


const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(5),
    marginTop: '80px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '40px',
      textAlign: 'center',
    }
  },
  progress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '80%',
    margin: 'auto',
  },
  iconRow: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  iconButton: {
    margin: theme.spacing(2),
  },
  container1: {
    backgroundImage: `url(${backg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: theme.spacing(5), // Ensure the content has padding
    borderRadius: '10px', // Optional: add rounded corners
  },
}));

const RoadmapHeader = ({ header, subheading, progress }) => {
  const classes = useStyles();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box className={classes.container1}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h1" sx={{ fontSize: matchesMD ?  matchesSM ? "1.7em" : "2.2rem" : "3.5rem", textAlign: 'center' }}>
            {header}
          </Typography>
          <br/>
          <Typography variant="subtitle1" sx={{ color:"black", fontSize: matchesMD ? matchesSM ? "1em" : "1.58rem" : "1.6rem",textAlign: 'center' }}>
            {subheading}
          </Typography>
          <LinearProgress variant="determinate" value={progress} className={classes.progress} />
          <Typography variant="body2" align="center">Progress: {progress}% complete</Typography>
        </Grid>
        <Grid item xs={12} className={classes.iconRow}>
          <IconButton className={classes.iconButton}>
            <SchoolIcon fontSize="large" />
          </IconButton>
          <IconButton className={classes.iconButton}>
            <InsightsIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoadmapHeader;