import React, {useEffect} from 'react';
import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import SectionsHeader from './components/SectionsHeader'
import maino from '../ui/blogl.png';
import google_vs_openai from '../ui/Designer (8).jpeg';
import stable_diffusion from '../ui/stable-diffusion.png';
import stable_diffusion_guid from '../ui/stable-diffusion-guid/photo/stable_diffusion.png';

import BlogIntro from './components/BlogIntroSections' 

import backg from '../ui/ssss.png';


const useStyles = makeStyles((theme) => ({
  gridContainer: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container1: {
    backgroundImage: `url(${backg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  dividerStyle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column', // Arrange elements in a column
    alignItems: 'flex-start', // Align text to the left
    padding: '20px',
  },
  lineStyle: {
    width: '100%',
    height: '1.5px', // Height of the horizontal line
    backgroundColor: '#004D49', // Change this to your desired line color
    marginBottom: '0px', // Spacing between line and text
  },
}));

const AiBlog = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'AI Blog Posts';
  }, []);

  return (
    <Grid>

      <Grid item xs={12}>
        <SectionsHeader
          header="Witness the Future: AI Startups Shaping Tomorrow"
          subheading="Access exclusive articles and case studies to deepen your understanding of AI and its applications."
          image={maino}
          altText="Alternative Text"
        />
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="What is Stable Diffusion?"
            description="Everything You Need To Know About Stable Diffusion"
            imageUrl={stable_diffusion_guid}
            altText="what is Stable Diffusion"
            url_link="/blog/stable-diffusion-guide"
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="Who Wins the ChatGPT vs Google Bard in 2024?"
            description="Siri who? ChatGPT & Bard, the AI DaVincis, clash in a 2024 battle for writing supremacy. Unleash your creativity, but beware the knowledge gap."
            imageUrl={google_vs_openai}
            altText="chatgpt VS google"
            url_link="/blog/chatgpt-vs-bard"
          />
        </Box>
      </Grid>
      

      
      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="Understanding Stable Diffusion"
            description="The magic behind Stable Diffusion lies in its architecture and training process."
            imageUrl={stable_diffusion}
            altText="stable_diffusion"
            url_link="/blog/stable-diffusion"
          />
        </Box>
      </Grid>
      

    </Grid>
  );
};

export default AiBlog;
