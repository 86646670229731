import React from 'react';
import { Grid, Typography, useMediaQuery, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import { Link } from "react-router-dom";
import maino from '../../ui/img-1.png';


const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2),
    marginTop: '80px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '40px',
      textAlign: 'center',
    },
  },

  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.between(500, 600)]:{
     margin: theme.spacing(6),
    },
  },

  learnButtonHero: {
    fontSize: "0.9rem",
    height: 50,
    width: 300,
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 350,
    },
  },

  iconContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop:"1em",
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop:"1em",
      marginBottom: theme.spacing(2),
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  icon: {
    width: 'auto',
    height: 'auto',
    maxHeight: '450px', // Set maximum height for larger screens
    [theme.breakpoints.down('sm')]: {
      maxHeight: '250px', // Adjust max height for smaller screens
    },
  },

}));

const MainHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'));


  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={6}>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item>
            <Grid container direction="column" justify="flex-start">
              <Grid item>
              <Typography variant="h1" sx={{ fontSize: matchesMD ?  matchesSM ? "1.7em" : "2.2rem" : "3.5rem" }}>
                  Master Stable Diffusion: Your Path to AI Creativity
                </Typography>
                <Typography variant="h2" sx={{ color: "#ABCC76", fontSize: matchesMD ?  matchesSM ? "1.2em" : "1.4rem" : "1.5em" }}>  
                  With Earngenix, explore in-depth tutorials and workflows to elevate your AI skills.
                </Typography>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={Link}
                  to="/stable-diffusion"
                  sx={{ borderRadius: 28 }}
                  className={classes.learnButtonHero}
                >
                  Stable Diffusion - Roadmap
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.iconContainer}>
        <img className={classes.icon} alt="principal" src={maino} />
      </Grid>
    </Grid>
  );
};

export default MainHeader;
