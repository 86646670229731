import React, { useEffect, useState, Suspense } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'; // For the loading spinner
import Box from '@mui/material/Box'; // For layout
import BlogNotFound from '../BlogNotFound';


function WorkflowsBlogs() {
  const { blogName } = useParams();
  const [BlogComponent, setBlogComponent] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    const loadBlogComponent = async () => {
      setLoading(true); // Start loading
      try {
        const component = await import(`./${blogName}`);
        const module = component.default || null;

        if (module && React.isValidElement(<module />)) {
          setBlogComponent(() => module);
        } else {
          console.error(`Invalid component loaded for '${blogName}'`);
          setBlogComponent(null);
        }
      } catch (error) {
        console.error('Error loading blog component:', error);
        setBlogComponent(null);
      } finally {
        setLoading(false); // Loading finished
      }
    };

    loadBlogComponent();
  }, [blogName]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense
            fallback={
              <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress /> {/* Circular loader for suspense */}
              </Box>
            }
          >
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
                <CircularProgress /> {/* Circular loader when loading is true */}
              </Box>
            ) : BlogComponent ? (
              <BlogComponent /> 
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="75vh"
                flexDirection="column"
                textAlign="center"
              >
                <BlogNotFound/>
              </Box> // Better UI for "Blog Not Found" case
            )}
          </Suspense>
        }
      />
    </Routes>
  );
}

export default WorkflowsBlogs;
