import { takeLatest, put, all, call } from 'redux-saga/effects';

import { USER_ACTION_TYPES } from './types';

import {
  signInSuccess,
  signInFailed,
  signUpSuccess,
  signUpFailed,
  signOutSuccess,
  signOutFailed,
  fetchUsersSuccess,
  fetchUsersFailed,
  userEditSuccess,
  userEditFailed,
  fetchDashboardSuccess,
  fetchDashboardFailed
} from './action';


import {
  signInUser,
  getUserDetails,
  checkUserToken,
  createUserWithEmailAndPassword,
  getUsersApi,
  editUserProfile,
  getAdminDashboard,
  emailSubscribed,
  } from '../../utils/api.utils';


export function* getSnapshotFromUserAuth() {
  try {
    const userSnapshot = yield call(
      getUserDetails
    );
    yield put(signInSuccess({...userSnapshot.data}));
  } catch (error) {
    yield put(signInFailed(error.data));
  }
}

export function* onGetSnapshotFromUserAuth() {
  yield takeLatest(USER_ACTION_TYPES.GET_SNAPSHOT_FROM_USER_AUTH, getSnapshotFromUserAuth);
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    yield call(
      signInUser,
      email,
      password
    );

    yield call(getSnapshotFromUserAuth);
  } catch (error) {
    yield put(signInFailed(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield call(checkUserToken);
    if (!userAuth) return;
    yield call(getSnapshotFromUserAuth);
  } catch (error) {
    yield put(signInFailed(error));
  }
}

export function* signUp({ payload: { firstname, lastname, email, password } }) {
  try {
    localStorage.removeItem('token');
    const { user } = yield call(
      createUserWithEmailAndPassword,
      firstname, 
      lastname,
      email, 
      password
    );
    yield put(signUpSuccess(user, { firstname }));
  } catch (error) {
    yield put(signUpFailed(error));
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(USER_ACTION_TYPES.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignUpStart() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_UP_START, signUp);
}


export function* signInAfterSignUp({ payload: { user, additionalDetails } }) {
  yield call(getSnapshotFromUserAuth);
}

export function* onSignUpSuccess() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_UP_SUCCESS, signInAfterSignUp);
}

export function* fetchUsersAsync() {
  try {
    const usersArray = yield call(getUsersApi);
    yield put(fetchUsersSuccess(usersArray.data));
  } catch (error) {
    yield put(fetchUsersFailed(error));
  }
}

export function* onFetchUsers() {
  yield takeLatest(
    USER_ACTION_TYPES.FETCH_USERS_START,
    fetchUsersAsync
  );
}

export function* signOut() {
  try {
    // yield call(signOutUser);
    localStorage.removeItem('token');
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailed(error));
  }
}

export function* onSignOutStart() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_OUT_START, signOut);
}


export function* editProfile({ payload: { id, firstName, lastName, email, mobileNo } }) {
  try {
    const user = yield call(
      editUserProfile,
      id,
      firstName, 
      lastName, 
      email, 
      mobileNo
    );
    yield put(userEditSuccess(user.data));
  } catch (error) {
    yield put(userEditFailed(error));
  }
}

export function* onUserEditStart() {
  yield takeLatest(USER_ACTION_TYPES.USER_EDIT_START, editProfile);
}

export function* fetchAdminDashboadAsync() {
  try {
    const usersArray = yield call(getAdminDashboard);
    yield put(fetchDashboardSuccess(usersArray.data));
  } catch (error) {
    yield put(fetchDashboardFailed(error));
  }
}

export function* onFetchAdminDashboad() {
  yield takeLatest(
    USER_ACTION_TYPES.FETCH_DASHBOARD_START,
    fetchAdminDashboadAsync
  );
}

export function* onEmailSubscribe() {
  yield takeLatest(USER_ACTION_TYPES.EMAIL_SUBSCRIBED_START, emailSubscribe);
}

export function* emailSubscribe({ payload: { email } }) {
  try {
    yield call(
      emailSubscribed,
      email
    );

    yield call(getSnapshotFromUserAuth);
  } catch (error) {
    yield put(signInFailed(error));
  }
}

export function* userSagas() {
  yield all([
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onFetchUsers),
    call(onSignOutStart),
    call(onUserEditStart),
    call(onFetchAdminDashboad),
    call(onEmailSubscribe),
    call(onGetSnapshotFromUserAuth),
  ]);
}






