export const COMMENT_ACTION_TYPES = {
  FETCH_COMMENT_START: 'comment/FETCH_COMMENT_START',
  FETCH_COMMENT_SUCCESS: 'comment/FETCH_COMMENT_SUCCESS',
  FETCH_COMMENT_FAILED: 'comment/FETCH_COMMENT_FAILED',

  SAVE_COMMENT_START: 'comment/SAVE_COMMENT_START',
  SAVE_COMMENT_SUCCESS: 'comment/SAVE_COMMENT_SUCCESS',
  SAVE_COMMENT_FAILED: 'comment/SAVE_COMMENT_FAILED',

  DELETE_COMMENT_START: 'comment/DELETE_COMMENT_START',
  DELETE_COMMENT_SUCCESS: 'comment/DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_FAILED: 'comment/DELETE_COMMENT_FAILED',

  SAVE_REPLY_START: 'comment/SAVE_REPLY_START',
  SAVE_REPLY_SUCCESS: 'Scomment/SAVE_REPLY_SUCCESS',
  SAVE_REPLY_FAILED: 'comment/SAVE_REPLY_FAILED',

};
