export const USER_ACTION_TYPES = {
  SET_CURRENT_USER: 'user/SET_CURRENT_USER',
  CHECK_USER_SESSION: 'user/CHECK_USER_SESSION',
  GOOGLE_SIGN_IN_START: 'user/GOOGLE_SIGN_IN_START',
  EMAIL_SIGN_IN_START: 'user/EMAIL_SIGN_IN_START',
  GET_SNAPSHOT_FROM_USER_AUTH: 'user/GET_SNAPSHOT_FROM_USER_AUTH',
  
  SIGN_IN_SUCCESS: 'user/SIGN_IN_SUCCESS',
  SIGN_IN_FAILED: 'user/SIGN_IN_FAILED',
  
  SIGN_UP_START: 'user/SIGN_UP_START', 
  SIGN_UP_SUCCESS: 'user/SIGN_UP_SUCCESS',
  SIGN_UP_FAILED: 'user/SIGN_UP_FAILED',
 
  SIGN_OUT_START: 'user/SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'user/SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILED: 'user/SIGN_OUT_FAILED',

  FETCH_USERS_START: 'user/FETCH_USERS_START',
  FETCH_USERS_SUCCESS: 'user/FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILED: 'user/FETCH_USERS_FAILED',

  USER_EDIT_START: 'user/USER_EDIT_START', 
  USER_EDIT_SUCCESS: 'user/USER_EDIT_SUCCESS',
  USER_EDIT_FAILED: 'user/USER_EDIT_FAILED',

  FETCH_DASHBOARD_START: 'user/FETCH_DASHBOARD_START',
  FETCH_DASHBOARD_SUCCESS: 'user/FETCH_DASHBOARD_SUCCESS',
  FETCH_DASHBOARD_FAILED: 'user/FETCH_DASHBOARD_FAILED',

  EMAIL_SUBSCRIBED_SUCCESS: 'user/EMAIL_SUBSCRIBED_SUCCESS',
  EMAIL_SUBSCRIBED_START: 'user/EMAIL_SUBSCRIBED_START',
};
