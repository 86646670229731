import { COMMENT_ACTION_TYPES } from './types';

export const COMMENT_INITIAL_STATE = {
  comment: [], // Holds the list of comments
  isLoading: false, // Loading state for API requests
  error: null, // Holds error information if any
};

export const commentReducer = (state = COMMENT_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    // Fetch comment actions
    case COMMENT_ACTION_TYPES.FETCH_COMMENT_START:
      return { 
        ...state, 
        isLoading: true,  // Set loading to true when fetch starts
        error: null       // Reset any previous error
      };

    case COMMENT_ACTION_TYPES.FETCH_COMMENT_SUCCESS:
      return { 
        ...state, 
        comment: payload, // Store fetched comments
        isLoading: false  // Set loading to false after success
      };

    case COMMENT_ACTION_TYPES.FETCH_COMMENT_FAILED:
      return { 
        ...state, 
        error: payload, // Set error in case of failure
        isLoading: false 
      };

    // Save comment actions
    case COMMENT_ACTION_TYPES.SAVE_COMMENT_START:
      return { 
        ...state, 
        isLoading: true,  // Set loading to true when saving starts
        error: null       // Reset any previous error
      };

    case COMMENT_ACTION_TYPES.SAVE_COMMENT_SUCCESS:
      return { 
        ...state, 
        comment: [...state.comment, payload], // Append new comment to the existing array
        isLoading: false  // Stop loading on success
      };

    case COMMENT_ACTION_TYPES.SAVE_COMMENT_FAILED:
      return { 
        ...state, 
        error: payload, // Capture error if save fails
        isLoading: false 
      };

    // Delete comment actions
    case COMMENT_ACTION_TYPES.DELETE_COMMENT_START:
      return { 
        ...state, 
        isLoading: true, // Set loading to true when delete starts
        error: null      // Reset any previous error
      };

    case COMMENT_ACTION_TYPES.DELETE_COMMENT_SUCCESS:
      return { 
        ...state, 
        comment: payload, // Update comments after successful deletion
        isLoading: false  // Stop loading after success
      };

    case COMMENT_ACTION_TYPES.DELETE_COMMENT_FAILED:
      return { 
        ...state, 
        error: payload,  // Set error if delete fails
        isLoading: false 
      };

    case COMMENT_ACTION_TYPES.SAVE_REPLY_START:
      return {
        ...state,
        isLoading: true,
      };

    case COMMENT_ACTION_TYPES.SAVE_REPLY_SUCCESS:

      const updatedComments = state.comment.map(c => {
        if (c.id === action.payload.parent) {
          return { ...c, replies: [action.payload, ...c.replies] };
        }
        return c;
      });

      return {
        ...state,
        comment: updatedComments,
        isLoading: false,
      };

    case COMMENT_ACTION_TYPES.SAVE_REPLY_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    // Default case for unhandled actions
    default:
      return state;
  }
};
