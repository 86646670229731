import React from 'react';
import { Grid, Typography, useMediaQuery, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import backg from '../../ui/ssss.png';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2),
    marginTop: '80px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '40px',
      textAlign: 'center',
    }
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.between(500, 600)]:{
     margin: theme.spacing(6),
    },
  },

  iconContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop:"1em",
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop:"1em",
      marginBottom: theme.spacing(2),
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
icon: {
    width: 'auto',
    height: 'auto',
    maxHeight: '450px', // Set maximum height for larger screens
    [theme.breakpoints.down('sm')]: {
      maxHeight: '250px', // Adjust max height for smaller screens
    },
  },
  container1: {
    backgroundImage: `url(${backg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    zIndex: 1,
    padding: theme.spacing(0.1), // Ensure the content has padding
    borderRadius: '10px', // Optional: add rounded corners
  },
}));

const SectionsHeader = ({ header, subheading, image, altText }) => {
  const classes = useStyles();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box className={classes.container1}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item>
              <Grid container direction="column" justify="flex-start">
                <Grid item>
                  <Typography variant="h1" sx={{ fontSize: matchesMD ?  matchesSM ? "1.7em" : "2.2rem" : "3.5rem" }}>
                    {header}
                  </Typography>
                  <br />
                  <Typography variant="subtitle1" sx={{ color:"black", fontSize: matchesMD ? matchesSM ? "1em" : "1.58rem" : "1.6rem" }}>
                    {subheading}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.iconContainer}>
          <img className={classes.icon} alt={altText} src={image} /> {/* Use the 'image' prop */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SectionsHeader;

