// import * as React from 'react';
// import { Outlet } from 'react-router-dom';
// import Footer from './Footer';

// import DrawerAppBar from './Header'

// function Home() {
  
//   return (
//     <React.Fragment>
//       <DrawerAppBar />
//       <Outlet />
//       <Footer />
//     </React.Fragment>
//   );
// }


// export default Home;


import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import DrawerAppBar from './Header';

function Home() {
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    content: {
      flex: 1,
    },
  };

  return (
    <div style={styles.root}>
      <DrawerAppBar />
      <div style={styles.content}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
