import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(5),
    marginTop: '80px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '40px',
      textAlign: 'center',
    },
  },
  headerBox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[2],
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  headerText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '1rem',
    color: theme.palette.grey[700],
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  sectionSpacing: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Box className={classes.headerBox}>
        <Typography className={classes.headerText} variant="h4" sx={{color:"white"}} gutterBottom>
          Privacy Policy
        </Typography>
      </Box>

      <Typography className={classes.subtitle} variant="subtitle1" gutterBottom>
        Last Updated: 2 November 2024
      </Typography>

      <Box className={classes.sectionSpacing}>
        <Typography paragraph>
          Thank you for visiting EarnGeniX. Protecting the privacy of our users is a priority for us. This Privacy Policy explains what types of information EarnGeniX collects, how we use it, and the choices you have regarding your information.
        </Typography>
      </Box>

      <Divider className={classes.sectionSpacing} />

      <Box className={classes.sectionSpacing}>
        <Typography variant="h5" gutterBottom>
          Information We Collect
        </Typography>
        <Typography paragraph>
          EarnGeniX may gather information in a few ways to improve your experience on our site:
        </Typography>
        <Typography variant="h6">Personal Information</Typography>
        <Typography paragraph>
          When you interact with EarnGeniX, such as by subscribing to our newsletter, leaving comments, or contacting us, we may collect personal details like your name and email address.
        </Typography>
        <Typography variant="h6">Usage Data</Typography>
        <Typography paragraph>
          We automatically collect data on how visitors interact with our site, which may include your IP address, browser type, access times, and referring pages. This helps us understand how EarnGeniX is being used and identify areas for improvement.
        </Typography>
      </Box>

      <Divider className={classes.sectionSpacing} />

      <Box className={classes.sectionSpacing}>
        <Typography variant="h5" gutterBottom>
          Use of Cookies
        </Typography>
        <Typography paragraph>
          EarnGeniX uses cookies and similar technologies to enhance your experience. Cookies are small files stored on your device that help remember your preferences and track user engagement on our site. You can modify your browser settings to refuse cookies, although this may affect some site functionality.
        </Typography>
      </Box>

      <Divider className={classes.sectionSpacing} />

      <Box className={classes.sectionSpacing}>
        <Typography variant="h5" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography paragraph>
          The data EarnGeniX collects is used for several purposes:
        </Typography>
        <Typography variant="h6">Enhancing the User Experience</Typography>
        <Typography paragraph>
          We use your feedback and browsing patterns to improve site functionality and content.
        </Typography>
        <Typography variant="h6">Communication</Typography>
        <Typography paragraph>
          If you’ve provided your email, we may send you updates or respond to inquiries you make.
        </Typography>
        <Typography variant="h6">Site Security and Monitoring</Typography>
        <Typography paragraph>
          We use usage data to protect EarnGeniX from security threats, unauthorized access, or potential misuse.
        </Typography>
      </Box>

      <Divider className={classes.sectionSpacing} />

      <Box className={classes.sectionSpacing}>
        <Typography variant="h5" gutterBottom>
          Sharing of Information
        </Typography>
        <Typography paragraph>
          EarnGeniX respects your privacy and does not sell or rent your information to third parties. However, we may share information with:
        </Typography>
        <Typography variant="h6">Service Providers</Typography>
        <Typography paragraph>
          We work with trusted providers to assist with site operations, including hosting, analytics, and email delivery. These providers are only given access to the data necessary to perform their tasks.
        </Typography>
        <Typography variant="h6">Legal Requirements</Typography>
        <Typography paragraph>
          We may disclose information if required to do so by law or in response to a valid legal request.
        </Typography>
      </Box>

      <Divider className={classes.sectionSpacing} />

      <Box className={classes.sectionSpacing}>
        <Typography variant="h5" gutterBottom>
          Protecting Children’s Privacy
        </Typography>
        <Typography paragraph>
          We are committed to protecting the privacy of young internet users. EarnGeniX does not knowingly collect personal information from children under 13. If you believe a child has provided us with their personal information, please contact us, and we will promptly remove it.
        </Typography>
      </Box>

      <Divider className={classes.sectionSpacing} />

      <Box className={classes.sectionSpacing}>
        <Typography variant="h5" gutterBottom>
          External Links
        </Typography>
        <Typography paragraph>
          EarnGeniX may include links to other websites for your convenience. However, we are not responsible for the privacy practices or content of these external sites, and we encourage you to review their policies before sharing any personal information.
        </Typography>
      </Box>

      <Divider className={classes.sectionSpacing} />

      <Box className={classes.sectionSpacing}>
        <Typography variant="h5" gutterBottom>
          Data Security
        </Typography>
        <Typography paragraph>
          We take reasonable precautions to protect your data and ensure its security. While we use measures to safeguard your information, please understand that no online service is entirely secure.
        </Typography>
      </Box>

      <Divider className={classes.sectionSpacing} />

      <Box className={classes.sectionSpacing}>
        <Typography variant="h5" gutterBottom>
          Updates to This Privacy Policy
        </Typography>
        <Typography paragraph>
          EarnGeniX may update this Privacy Policy occasionally to reflect changes in our practices or for legal reasons. We encourage you to check this page periodically for the latest version.
        </Typography>
      </Box>

      <Divider className={classes.sectionSpacing} />

      <Box className={classes.sectionSpacing}>
        <Typography variant="h5" gutterBottom>
          Your Consent
        </Typography>
        <Typography paragraph>
          By using EarnGeniX, you agree to the terms of this Privacy Policy.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
