import { takeLatest, all, call, put } from 'redux-saga/effects';

import { saveCommentApi, getCommentApi, deleteCommentApi, saveReplyApi } from '../../utils/api.utils';

import {
  saveCommentSuccess,
  saveCommentFailed,
  fetchCommentSuccess,
  fetchCommentFailed,
  deleteCommentSuccess,
  deleteCommentFailed,
  saveReplySuccess,
  saveReplyFailed
} from './action';

import { COMMENT_ACTION_TYPES } from './types';


export function* saveComment({ payload: { postSlug, section, commentData } }) {
  try {
    // Call the saveCommentApi function with the correct parameters
    const savedComment = yield call(
      saveCommentApi,
      postSlug,       // The post identifier
      section,        // Section of the post
      commentData,
      );    
    
    // Dispatch saveCommentSuccess action with the saved comment data
    yield put(saveCommentSuccess(savedComment.data));
  } catch (error) {
    // In case of error, dispatch saveCommentFailed action
    yield put(saveCommentFailed(error));
  }
}

export function* onSaveCommentStart() {
  yield takeLatest(
    COMMENT_ACTION_TYPES.SAVE_COMMENT_START, 
    saveComment);
}


export function* fetchCommentAsync({ payload: {postSlug, section} }) {
  try {
    const commentArray = yield call(
      getCommentApi,
      postSlug,
      section);
    yield put(fetchCommentSuccess(commentArray.data));
  } catch (error) {
    yield put(fetchCommentFailed(error));
  }
}


export function* saveReply({ payload }) {
  try {
    const reply = yield call(saveReplyApi, payload);
    yield put(saveReplySuccess(reply.data));
  } catch (error) {
    yield put(saveReplyFailed(error));
  }
}

export function* onSaveReplyStart() {
  yield takeLatest(COMMENT_ACTION_TYPES.SAVE_REPLY_START, saveReply);
}

// export function* onFetchComment() {
//   console.log("inot onFetchComment===asga====")
//   yield takeLatest(
//     COMMENT_ACTION_TYPES.FETCH_COMMENT_START,
//     fetchCommentAsync);
// }

export function* onFetchComment() {
  yield takeLatest(
    COMMENT_ACTION_TYPES.FETCH_COMMENT_START,
    fetchCommentAsync
  );
}


export function* deleteComment({ payload: { id,CommentArray } }) {
  try {
    const comment = yield call( deleteCommentApi, id);    
    yield put(deleteCommentSuccess(CommentArray));
  } catch (error) {
    yield put(deleteCommentFailed(error));
  }
}

export function* onDeleteCommentStart() {
  yield takeLatest(
    COMMENT_ACTION_TYPES.DELETE_COMMENT_START, 
    deleteComment);
}



export function* commentSaga() {
  yield all([
    call(onFetchComment),
    call(onSaveCommentStart),
    call(onDeleteCommentStart),
    call(onSaveReplyStart),
  ]);
}
