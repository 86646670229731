import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { selectCurrentUser } from './store/user/selector';
import { checkUserSession, getSnapshotFromUserAuth } from './store/user/action';
import useScrollRestoration from './useScrollRestoration';
import theme from './components/ui/Theme';
import API from './utils/api';

// Components
import Home from './components/main/Home';
import VideoGrid from './components/main/LandingPage';
import AiEarning from './components/main/AiEarning';
import AiTutorials from './components/main/AiTutorials';
import AiWorkflows from './components/main/AiWorkflows';
import Roadmap from './components/main/Roadmap';
import AiBlog from './components/main/AiBlog';
import AiEarningBlogs from './components/main/blog/earning';
import TutorialsBlogs from './components/main/blog/tutorials';
import WorkflowsBlogs from './components/main/blog/workflows';
import AiBlogs from './components/main/blog/blogs';
import SignIn from './components/main/Signin';
import SignUp from './components/main/Signup';
import Error from './components/Error';
import PrivacyPolicy from './components/main/Privacy';

const clientId = "182767965221-25lq6c03vknceephnqbbjgm8r8dh7h2q.apps.googleusercontent.com";

const GoogleOneTap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCredentialResponse = async (response) => {
    try {
      const { data } = await API.post('/auth-return/', {
        token: response.credential,
      });

      if (data.status === 'success') {
        // Instead of defaulting to '/' when no redirectUrl is set,
        // we can use the current location if no redirectUrl exists.
        const redirectUrl = localStorage.getItem('redirectUrl') || window.location.pathname;

        localStorage.setItem("token", JSON.stringify(data));
        localStorage.removeItem('redirectUrl');
        dispatch(getSnapshotFromUserAuth());
        navigate(redirectUrl);
      } else {
        console.error('Error logging in with Google:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const googleScript = document.createElement('script');
    googleScript.src = "https://accounts.google.com/gsi/client";
    googleScript.async = true;
    googleScript.onload = () => {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCredentialResponse,
        auto_select: true,
      });
      window.google.accounts.id.prompt();
    };
    document.body.appendChild(googleScript);
  }, []);

  return null;
};

function App() {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [showGoogleOneTap, setShowGoogleOneTap] = useState(false);
  useScrollRestoration();

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch]);

  useEffect(() => {
    // Introduce a delay to allow currentUser to be set properly
    const timer = setTimeout(() => {
      if (currentUser && Object.keys(currentUser).length > 0) {
        setShowGoogleOneTap(false); // User is logged in
      } else {
        setShowGoogleOneTap(true); // User is not logged in
      }
    }, 500); // 500ms delay

    // Cleanup timer if component unmounts
    return () => clearTimeout(timer);
  }, [currentUser]);

  return (
    <ThemeProvider theme={theme}>
      {/* Show GoogleOneTap only if the user is not logged in */}
      {showGoogleOneTap && (
        <GoogleOAuthProvider clientId={clientId}>
          <GoogleOneTap />
        </GoogleOAuthProvider>
      )}
      
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<VideoGrid />} />
          <Route path="/earning-ideas" element={<AiEarning />} />
          <Route path="/earning-ideas/:blogName" element={<AiEarningBlogs />} />
          <Route path="/tutorials" element={<AiTutorials />} />
          <Route path="/tutorials/:blogName" element={<TutorialsBlogs />} />
          <Route path="/workflows" element={<AiWorkflows />} />
          <Route path="/workflows/:blogName" element={<WorkflowsBlogs />} />
          <Route path="/blog" element={<AiBlog />} />
          <Route path="/blog/:blogName" element={<AiBlogs />} />
          <Route path="/stable-diffusion" element={<Roadmap />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Route>
        <Route path="signin" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
