import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1600,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 280,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    '&:hover': {
      backgroundColor: '#f0f0f0', // Change to desired highlight color
      transition: 'background-color 0.3s ease-in-out',
    },
  },
  blogImage: {
    width: '100%', // Ensure the image takes up the full width of the container
    height: 'auto',
    maxHeight: 280,
    objectFit: 'cover', // Ensure the image covers the container while maintaining aspect ratio
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%', // For smaller screens, adjust as needed
      height: '25vh',
      objectFit: 'cover',
    },
  },
}));

const BlogIntro = ({ title, description, imageUrl, altText, url_link }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Grid container 
      component={Link} style={{ textDecoration: 'none' }} to={url_link}
      justifyContent="space-around" alignItems="center" className={classes.container}>
      <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
        <Typography variant="h2" align="center" sx={{ fontSize: matchesMD ? matchesSM ? '1.45rem' : '1.5rem' : '1.7rem' }} gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" align="center" 
          sx={{ fontSize: matchesMD ? matchesSM ? "1.07rem" : "1.1rem" : "1.25rem" }} 
          display={{ xs: 'block', sm: 'block', md: 'none' }} gutterBottom>
          {description}
        </Typography>
      </Grid>
      {/* Render the description only on md screens */}
      {isMdScreen && (
        <Grid item xs={12} sm={4} md={4} className={classes.gridItem}>
          <Typography variant="body1" align="center" 
            sx={{ fontSize: matchesMD ? matchesSM ? "1.07rem" : "1.1rem" : "1.25rem" }} 
            display={{ xs: 'none', sm: 'block' }} gutterBottom>
            {description}
          </Typography>
        </Grid>
      )}
      {/* Render the image for all screens */}
      <Grid item xs={12} sm={6} md={4} justifyContent="center" alignItems="center" alignText="center" className={classes.gridItem}>
        <img src={imageUrl} alt={altText} className={classes.blogImage} />
      </Grid>
    </Grid>
  );
};

export default BlogIntro;
