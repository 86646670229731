import React from 'react';
import { Grid, Typography, useMediaQuery, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CopyrightIcon from '@mui/icons-material/Copyright';

import logo1 from '../ui/logo-2.png';
import backgroundImg from '../ui/footer1.png';

const useStyles = makeStyles((theme) => ({
  footer: {
    background: `url(${backgroundImg}) center center/cover no-repeat`,
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  logoContainer: {
    display: 'block',
  },
  logo: {
    width: '50%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '25%',
    },
  },
  link: {
    textAlign: 'center',
    '& h4': {
      margin: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.6rem',
      },
    },
  },
  socialIcon: {
    margin: theme.spacing(1),
  },
  copyright: {
    marginLeft: theme.spacing(1),
    fontSize: '0.2rem',
    color: "#004D49",
    display: 'flex',
    alignItems: 'center',
  },
  pipe: {
    margin: '0 10px',
    color: '#000',
  },
  privacyLink: {
    color: '#004D49',
    textDecoration: 'none',
    fontSize: '0.8rem',
    marginLeft: theme.spacing(1),
  },
}));

const items = ['EARNING IDEAS', 'TUTORIALS', 'WORKFLOWS', 'BLOG'];

const Footer = () => {
  const classes = useStyles();
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <footer className={classes.footer}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={6} sm={3}>
          <Button
            disableRipple
            className={classes.logoContainer}
          >
            <img alt="company logo" className={classes.logo} src={logo1} />
          </Button>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 1, sm: 3, md: 5, xl: 7 }}
          >
            {items.map((item, index) => (
              <React.Fragment key={item}>
                <Grid item className={classes.link}>
                  <Typography variant="h3" sx={{
                      color: "#004D49",
                      fontSize: matchesMD ? "1.1rem" : "1.2rem",
                    }}>
                    {item}
                  </Typography>
                </Grid>
                {index < items.length - 1 && (
                  <Grid item>
                    <Typography variant="h6" className={classes.pipe}>
                      |
                    </Typography>
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item className={classes.socialIcon}>
              <FacebookIcon color="disabled" />
            </Grid>
            <Grid item className={classes.socialIcon}>
              <TwitterIcon color="disabled" />
            </Grid>
            <Grid item className={classes.socialIcon}>
              <InstagramIcon color="disabled" />
            </Grid>
            <Grid item className={classes.socialIcon}>
              <YouTubeIcon color="disabled" />
            </Grid>
          </Grid>
        </Grid>
<Grid item xs={12} sm={6} md={3}>
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    spacing={1} // Adjust spacing between items for better readability
  >
<Grid item>
  <a href="https://www.earngenix.com/privacy" className={classes.privacyLink}>
    Privacy Policy
  </a>
</Grid>

    <Grid item>
      <Typography variant="h6" className={classes.pipe} sx={{ mx: 1 }}>
        |
      </Typography>
    </Grid>
    <Grid item sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
      <CopyrightIcon color="disabled" sx={{ fontSize: 15, mr: 0.5 }} />
      <Typography variant="body2" sx={{ fontSize: '0.9rem', color: "#004D49"}}>
        EarnGeniX. All rights reserved.
      </Typography>
    </Grid>



          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
