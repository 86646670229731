
import { createSelector } from 'reselect';

export const selectCurrentUser = (state) => state.user.currentUser;

export const selectSigninError = (state) => state.user.signinError;


const selectUserReducer = (state) => state.user;

export const selectUsersMap = createSelector(
  [selectUserReducer],
  (userSlice) => userSlice.allUsers
);

// export const selectScheduleDetail = createSelector(
//   [selectScheduleReducer],
//   (scheduleSlice) => scheduleSlice.scheduleDetail
// );

export const selectUsersIsLoading = createSelector(
  [selectUserReducer],
  (usersSlice) => usersSlice.isLoading
);

export const selectDashboadMap = createSelector(
  [selectUserReducer],
  (usersSlice) => usersSlice.adminDashboard
);

export const selectDashboadIsLoading = createSelector(
  [selectUserReducer],
  (usersSlice) => usersSlice.isDashboadLoding
);

// export const selectScheduleDetailIsLoading = createSelector(
//   [selectScheduleReducer],
//   (scheduleSlice) => scheduleSlice.isLoadingDetail
// );
