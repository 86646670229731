import React, { useState, useEffect } from 'react';
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  List,
  ListItem,
  Button,
  Box,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import RoadmapHeader from './components/RoadmapHeader';
const BASE_URL = process.env.REACT_APP_FRONTEND_URL;


const levels = [
  {
    label: 'SD Basics 1: Beginner Foundations',
    steps: [
      {
        label: 'Introduction to Stable Diffusion & A1111 Web UI',
        description: [
          {
            text: 'What is Stable Diffusion? and its capabilities.',
            link: `${BASE_URL}/blog/stable-diffusion-guide`,
          },
          {
            text: 'Stable Diffusion Setup Guide: Windows, Linux, Mac.',
            link: `${BASE_URL}/blog/stable-diffusion-guide#start-with-stable-diffusion`,
          },
          { text: 'Understanding the A1111 Web UI and its features.' },
          { text: 'Initial configuration and troubleshooting tips.' },
          { text: 'Basic navigation through the user interface.' },
        ],
      },
      {
        label: 'Basic Text-to-Image Generation',
        description: [
          { text: 'Introduction to generating images from text prompts.' },
          { text: 'Common settings: CFG scale, steps, samplers, etc.' },
          { text: 'Using example prompts to generate initial images.' },
          { text: 'Understanding prompt response and output variations.' },
          { text: 'Saving and sharing generated images.' },
        ],
      },
      {
        label: 'Understanding Checkpoint Models',
        description: [
          { text: 'What Checkpoint Models Are' },
          { text: 'Using Models Effectively' },
          { text: 'Switching Between Models' },
        ],
      },
      {
        label: 'Prompt Engineering',
        description: [
          { text: 'How to Structure Prompts.' },
          { text: 'Using Adjectives and Context' },
          { text: 'Prompt Weighting' },
          { text: 'Using Multiple Prompts' },
          { text: 'Controlling Style and Composition' },
        ],
      },
      {
        label: 'Troubleshooting Common Issues',
        description: [
          { text: 'Bad Outputs' },
          { text: 'Unresponsive Interface' },
          { text: 'VRAM or Memory Issues' },
          { text: 'Slow Image Generation' },
        ],
      },
    ],
  },
  {
    label: 'SD Pathway 2: Beginner to Intermediate Transition',
    steps: [
      {
        label: 'Image-to-Image Generation',
        description: [
          { text: 'Introduction to Image-to-Image' },
          { text: 'Denoising Strength' },
          { text: 'Use Cases' },
        ],
      },
      {
        label: 'A1111 Extensions',
        description: [
          { text: 'Introduction to Useful Extensions' },
          { text: 'Recommended Extensions' },
          { text: 'How Extensions Enhance Workflow' },
        ],
      },
      {
        label: 'Upscalers and VAE Integration',
        description: [
          { text: 'Introduction to Built-in Upscalers' },
          { text: 'Using Upscalers in A1111 Web UI' },
          { text: 'What is VAE?' },
          { text: 'How to Use VAE for Better Image Generation' },
        ],
      },
      {
        label: 'Using LoRA, Textual Inversion, and Hypernetworks',
        description: [
          { text: 'What are LoRA, Textual Inversion, and Hypernetworks?' },
          { text: 'Using LoRA' },
          { text: 'Textual Inversion' },
          { text: 'Practical Use Cases' },
        ],
      },
      {
        label: 'ControlNet',
        description: [
          { text: 'Introduction to ControlNet' },
          { text: 'Pose Transfer Using ControlNet' },
          { text: 'Setting Up ControlNet in A1111 Web UI' },
        ],
      },
    ],
  },
  {
    label: 'SD Mastery 3: Intermediate Mastery',
    steps: [
      {
        label: 'Outpainting - Seamlessly Extend Images',
        description: [
          { text: 'What is Outpainting?' },
          { text: 'Use ControlNet for Outpainting' },
          { text: 'Adjust ControlNet Settings for the Best Results' },
        ],
      },
      {
        label: 'Adetailer for Face Refinement',
        description: [
          { text: 'Installation and Basic Settings' },
          { text: 'Auto Fix: Face and Hands' },
          { text: 'Add Makeup, Change Clothing, and Enhance Eyes' },
          { text: 'Anime Image Enhancement' },
        ],
      },
      {
        label: 'Deforum Video Generation',
        description: [
          { text: 'Introduction to Deforum' },
          { text: 'Using Deforum in A1111' },
          { text: 'Video Generation Workflow' },
        ],
      },
      {
        label: 'AnimateDiff for Video Generation',
        description: [
          { text: 'What is AnimateDiff?' },
          { text: 'How to Use AnimateDiff' },
          { text: 'Creating a Smooth Video Sequence' },
          { text: 'Advanced Techniques' },
        ],
      },
      {
        label: 'Animate Still Images',
        description: [
          { text: 'What Does It Mean to Animate a Still Image?' },
          { text: 'How to Animate a Still Image' },
          { text: 'Techniques for Realistic Animation' },
          { text: 'Examples' },
        ],
      },
      {
        label: 'Making Infinite Zoom Videos',
        description: [
          { text: 'What is an Infinite Zoom Video?' },
          { text: 'How to Create Infinite Zoom Videos' },
          { text: 'Examples' },
        ],
      },
    ],
  },
  {
    label: 'SD Pro 4: Advanced Techniques',
    steps: [
      {
        label: 'Enhancing Images with Regional Prompting',
        description: [
          { text: 'What is Regional Prompting' },
          { text: 'Overview of General Settings' },
          { text: 'Diving Into Matrix Mode' },
          { text: 'Using Masks for Precise Control' },
          { text: 'Prompt Section for Fine-Tuning' },
        ],
      },
      {
        label: 'Training a LoRA Model, Textual Inversion, and Hypernetworks',
        description: [
          { text: 'Training LoRA Model' },
          { text: 'Training Textual Inversion' },
          { text: 'Training Hypernetworks' },
        ],
      },
      {
        label: 'DreamBooth Training: Customizing AI Models & LoRAs',
        description: [
          { text: 'Model Training' },
          { text: 'LoRA Training' },
          { text: 'Character Training' },
          { text: 'Style Training' },
          { text: 'Object Training' },
        ],
      },
      {
        label: 'Generating Consistent Faces',
        description: [
          { text: 'Challenges in Generating Consistent Faces' },
          { text: 'Techniques for Consistency' },
          { text: 'Practical Example' },
        ],
      },
      {
        label: 'Python API Automation and Batch Processing',
        description: [
          { text: 'Generating multiple images at once' },
          { text: 'Automating workflows with Python Scripts' },
        ],
      },
    ],
  },
];



const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2),
    marginTop: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
      textAlign: 'center',
    },
  },
  stepperContainer: {
    overflowX: 'auto', // Add horizontal scrolling for small screens
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  section: {
    marginTop: theme.spacing(3),
  },
}));

const Roadmap = () => {
  const classes = useStyles();
  useEffect(() => {
    document.title = 'Stable Diffusion - Step-by-Step Roadmap';
  }, []);
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [activeStep, setActiveStep] = useState(0);
  const [activeLevelStep, setActiveLevelStep] = useState(0);

  const handleLevelChange = (index) => {
    setActiveStep(index);
    setActiveLevelStep(0);
  };

  const handleStepClick = (index) => {
    setActiveLevelStep(index);
  };

  const roadmapHeaderProps = {
    header: 'Stable Diffusion Learning Roadmap',
    subheading: 'Master Stable Diffusion in 4 Levels',
    progress: 50, // Example progress value (you can make this dynamic based on the user's progress)
  };

  return (
    <Box>
      <RoadmapHeader
        header={roadmapHeaderProps.header}
        subheading={roadmapHeaderProps.subheading}
        progress={roadmapHeaderProps.progress}
      />

      <div className={classes.container}>
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontSize: matchesMD ? (matchesSM ? '1.3rem' : '1.35rem') : '1.45rem',
            letterSpacing: matchesMD ? '0.4px' : '0.9px',
            lineHeight: matchesMD ? '33px' : '40px',
          }}
          gutterBottom
        >
          Stable Diffusion Roadmap - AUTOMATIC1111
        </Typography>

        <Box className={classes.stepperContainer} sx={{ marginTop: matchesMD ? '1.5rem' : '2rem' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {levels.map((level, index) => (
              <Step key={level.label}>
                <StepLabel onClick={() => handleLevelChange(index)} style={{ cursor: 'pointer' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: matchesMD ? (matchesSM ? 520 : 520) : 501,
                      fontSize: matchesMD ? (matchesSM ? '0.9rem' : '1.2rem') : '1.25rem',
                      letterSpacing: matchesMD ? '0.2px' : '0.5px',
                      lineHeight: matchesMD ? '25px' : '35px',
                      color: index <= activeStep ? 'inherit' : 'rgba(0, 0, 0, 0.6)',
                    }}
                  >
                    {level.label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Paper elevation={3} style={{ padding: '16px', marginTop: '24px' }}>
          <Typography
            variant="h2"
            sx={{
              color: '#004D49',
              fontSize: matchesMD ? '1.2rem' : '1.35rem',
              letterSpacing: matchesMD ? '0.4px' : '0.9px',
              lineHeight: matchesMD ? '33px' : '40px',
            }}
            gutterBottom
          >
            {levels[activeStep].label}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: matchesMD ? '1.2rem' : '1.30rem',
              letterSpacing: matchesMD ? '0.4px' : '0.9px',
              lineHeight: matchesMD ? '33px' : '40px',
            }}
            gutterBottom
          >
            Detailed Steps:
          </Typography>
          <Stepper activeStep={activeLevelStep} orientation="vertical">
            {levels[activeStep].steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel onClick={() => handleStepClick(index)} style={{ cursor: 'pointer' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: matchesMD ? (matchesSM ? '1.1rem' : '1.25rem') : '1.29rem',
                      letterSpacing: matchesMD ? '0.2px' : '0.5px',
                      lineHeight: matchesMD ? '25px' : '35px',
                    }}
                  >
                    {step.label}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <List>
                    {step.description.map((point, idx) => (
                      <ListItem key={idx}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: matchesMD ? (matchesSM ? '1.1rem' : '1.25rem') : '1.29rem',
                            letterSpacing: matchesMD ? '0.2px' : '0.5px',
                            lineHeight: matchesMD ? '25px' : '35px',
                          }}
                          component={point.link ? 'a' : 'p'}
                          href={point.link || undefined}
                          target={point.link ? '_blank' : undefined}
                          style={{ textDecoration: point.link ? 'underline' : 'none', color: point.link ? '#0073e6' : 'inherit' }}
                        >
                          {point.text}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </div>
    </Box>
  );
};

export default Roadmap;
