import React, {useEffect} from 'react';
import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SectionsHeader from './components/SectionsHeader'
import stable_diffusion_install from '../ui/install_stable_diffusion.png';
import dev_image from '../ui/flux-install/flux_install.png';
import mac_install_main from '../ui/mac-install/mac_install_main.png';

import BlogIntro from './components/BlogIntroSections' 

import backg from '../ui/ssss.png';
import maino from '../ui/news.png';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container1: {
    backgroundImage: `url(${backg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  dividerStyle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column', // Arrange elements in a column
    alignItems: 'flex-start', // Align text to the left
    padding: '20px',
  },
  lineStyle: {
    width: '100%',
    height: '1.5px', // Height of the horizontal line
    backgroundColor: '#004D49', // Change this to your desired line color
    marginBottom: '0px', // Spacing between line and text
  },
}));

const AiTutorials = () => {
  const classes = useStyles();
  
  useEffect(() => {
    document.title = 'Tutorials - Master AI Tools & Techniques';
  }, []);

  return (
    <Grid>

      <Grid item xs={12}>
        <SectionsHeader
          header="Master AI Tools & Techniques"
          subheading="Step-by-step guides to unlock the full potential of AI—from installations to advanced video and image creation."
          image={maino}
          altText="Alternative Text"
        />
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>
      
      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="How to Install Flux AI Locally on ComfyUI"
            description="Installing Flux AI Locally on ComfyUI: A Quick Guide"
            imageUrl={dev_image}
            altText="Flux Install Guide On Comfyui"
            url_link="/tutorials/install-flux-comfyui"
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>


      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="How to install Stable Diffusion Locally"
            description="Automatic1111: On a Windows PC with Nvidia GPU"
            imageUrl={stable_diffusion_install}
            altText="Stable Diffusion install guide"
            url_link="/tutorials/install-stable-diffusion"
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="How to Install Stable Diffusion on Mac"
            description="Complete Setup Guide for M1/M2 Macs"
            imageUrl={mac_install_main}
            altText="How to Install Stable Diffusion on Mac"
            url_link="/tutorials/install-stable-diffusion-mac"
          />
        </Box>
      </Grid>

    </Grid>
  );
};

export default AiTutorials;
