import React, { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MainHeader from './components/MainHeader'
import Divider from './components/Divider' 
import BlogIntro from './components/BlogIntroSections' 
import m_stock_image from '../ui/Designer (7).jpeg';
import stable_diffusion_install from '../ui/install_stable_diffusion.png';
import backg from '../ui/ssss.png';
import m_stock_image1 from '../ui/cat.jpeg';
import dev_image from '../ui/flux-install/flux_install.png';
import flux_install from '../ui/cogvideo/CogVideoX.png';
import stable_diffusion_guid from '../ui/stable-diffusion-guid/photo/stable_diffusion.png';


const useStyles = makeStyles((theme) => ({
  gridContainer: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container1: {
    backgroundImage: `url(${backg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    zIndex: 1,
    padding: theme.spacing(0.1), // Ensure the content has padding
    borderRadius: '10px', // Optional: add rounded corners
  },

  dividerStyle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column', // Arrange elements in a column
    alignItems: 'flex-start', // Align text to the left
    padding: '20px',
  },
  lineStyle: {
    width: '100%',
    height: '1px', // Height of the horizontal line
    backgroundColor: '#004D49', // Change this to your desired line color
    marginBottom: '0px', // Spacing between line and text
  },
}));

const VideoGrid = () => {
  const classes = useStyles();
  
  useEffect(() => {
    document.title = 'EarnGeniX - Your ultimate resource for mastering AI tools like Stable Diffusion, Comfy UI, and Automatic 11.11. Discover tutorials and workflows, along with earning ideas to turn your skills into profit.';
  }, []);


  return (
    <Grid>

      <Grid item xs={12}>
        <Box className={classes.container1}>
          <MainHeader/>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <Divider text="Tutorials"/>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="How to Install Flux AI Locally on ComfyUI"
            description="Installing Flux AI Locally on ComfyUI: A Quick Guide"
            imageUrl={dev_image}
            altText="Flux Install Guide On Comfyui"
            url_link="/tutorials/install-flux-comfyui"
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="How to install Stable Diffusion Locally"
            description="Automatic1111: On a Windows PC with Nvidia GPU"
            imageUrl={stable_diffusion_install}
            altText="Stable Diffusion install guide"
            url_link="/tutorials/install-stable-diffusion"
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <Divider text="Workflows"/>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="How to Install CogVideoX in ComfyUI"
            description="CogVideoX Text-to-Video and Image-to-Video Generation"
            imageUrl={flux_install}
            altText="Stable Diffusion install guide"
            url_link="/workflows/install-cogvideox-comfyui"
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <Divider text="Featured Articles"/>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="What is Stable Diffusion?"
            description="Everything You Need To Know About Stable Diffusion"
            imageUrl={stable_diffusion_guid}
            altText="what is Stable Diffusion"
            url_link="/blog/stable-diffusion-guide"
          />
        </Box>
      </Grid>
      
      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <Divider text="Earning Ideas"/>
        </Box>
      </Grid>

       <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="Create A Faceless Youtube Channel For AI Animated Stories"
            description="AI Animated Story: Choosing the Right Youtube Niche for Success"
            imageUrl={m_stock_image1}
            altText="Alternative Text"
            url_link="/earning-ideas/create-a-faceless-youtube-channel-for-ai-animated-stories"
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <div className={classes.dividerStyle}>
            <div className={classes.lineStyle}></div>
          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.gridContainer}>
          <BlogIntro
            title="Monetize Your Imagination: Make Money Online with AI-Powered Stock Images"
            description="Automate Your Art: Generate and Sell Images Effortlessly with AI."
            imageUrl={m_stock_image}
            altText="Alternative Text"
            url_link="/earning-ideas/how-to-make-money-using-stock-images-ai"
          />
        </Box>
      </Grid>

    </Grid>
  );
};

export default VideoGrid;
