import React from 'react';

const SectionDivider = ({ text }) => {
  const dividerStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column', // Arrange elements in a column
    alignItems: 'flex-start', // Align text to the left
    padding: '20px',
  };

  const lineStyle = {
    width: '100%',
    height: '3px', // Height of the horizontal line
    backgroundColor: '#004D49', // Change this to your desired line color
    marginBottom: '0px', // Spacing between line and text
  };

  const contentStyle = {
    backgroundColor: '#004D49', // Change this to your desired box color
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
  };

  const textStyle = {
    fontSize: '13px', // Adjust the font size as needed
    color: '#FFFFFF', // Change this to your desired text color
    textTransform: 'uppercase',
    fontWeight: 'bold',
  };

  return (
    <div style={dividerStyle}>
      <div style={lineStyle}></div>
      <div style={contentStyle}>
        <span style={textStyle}>{text}</span>
      </div>
    </div>
  );
};

export default SectionDivider;
