import API from './api';


export const signInUser = async (email, password) => {
  if (!email || !password) return;
  const { data } = await API.post(`token/`,{'email': email,'password':password})
  localStorage.setItem("token", JSON.stringify(data));
  localStorage.setItem("activeItem", "Dashboard");
  localStorage.setItem("selectedSubheading", null);
  return data
};


export const getUserDetails = async () => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (!token) return;
    try {
      return await API.get(`users/currentuser/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
    }
};


export const checkUserToken = () => {
  return new Promise((resolve, reject) => {
  const token = JSON.parse(localStorage.getItem('token'));
  if (!token) reject();
  resolve(token);
  });
};


export const createUserWithEmailAndPassword = async (firstname, lastname, email, password) => {
  if (!password || !firstname || !email) return;

  const uploadData = new FormData();
  uploadData.append('first_name', firstname);
  uploadData.append('last_name', lastname);
  uploadData.append('email', email);
  uploadData.append('password', password);

  try {
      const { newdata } = await API.post(`users/`,uploadData)
      const { data } = await API.post(`token/`,{'email': email,'password':password})
      localStorage.setItem("token", JSON.stringify(data));
      return {"first_name":firstname, "last_name":lastname, "email":email, "password":password}
    } catch (error) {
    }

};


export const saveStudentApi = async ( formData ) => {
  const token = JSON.parse(localStorage.getItem('token'));

  // if (!token || !startTime || !endTime || !batchTime || !repeatOn || !valueRadio) return;

  // const uploadData = {"start_time":startTime, "end_time":endTime, "batch_time":batchTime, "repeat_on":repeatOn, "min_hour":valueRadio}
  try {
      // const { newdata } = await API.post(`users/`,uploadData)
      return await API.post(`student/`, formData, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error adding schedules', error.message);
    }

};

export const saveScheduleApi = async (startTime, endTime, batchTime, repeatOn, valueRadio) => {
  const token = JSON.parse(localStorage.getItem('token'));

  // if (!token || !startTime || !endTime || !batchTime || !repeatOn || !valueRadio) return;

  const uploadData = {"start_time":startTime, "end_time":endTime, "batch_time":batchTime, "repeat_on":repeatOn, "min_hour":valueRadio}
  try {
      // const { newdata } = await API.post(`users/`,uploadData)
      return await API.post(`schedules/`, uploadData, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error adding schedules', error.message);
    }

};


export const getBookingOccurrences = async (date) => {
  try {
      return await API.get(`public-occurrence/?date=${date}`);
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};


export const getClassRoomApi = async (page) => {
  const token = JSON.parse(localStorage.getItem('token'));
  
  if (!token) {
    return; // No token, return early
  }

  try {
    const queryParams = new URLSearchParams({
      page,
    });

    const response = await API.get(`classes/?${queryParams.toString()}`, {
      headers: {
        Authorization: `Bearer ${token.access}`,
      },
    });

    return response.data; // Assuming the API response is in the format you provided earlier.
  } catch (error) {
    console.log('error fetching students', error.message);
    throw error; // Rethrow the error so it can be caught in the saga
  }
};


export const getStudentsApi = async (page, searchParams) => {
  const token = JSON.parse(localStorage.getItem('token'));
  
  if (!token) {
    return; // No token, return early
  }

  try {
    const queryParams = new URLSearchParams({
      page,
      search: searchParams.search,
      status: searchParams.status,
      class: searchParams.class,
      gender: searchParams.gender.join(','), // Join the array to a comma-separated string
    });

    const response = await API.get(`student/?${queryParams.toString()}`, {
      headers: {
        Authorization: `Bearer ${token.access}`,
      },
    });

    return response.data; // Assuming the API response is in the format you provided earlier.
  } catch (error) {
    console.log('error fetching students', error.message);
    throw error; // Rethrow the error so it can be caught in the saga
  }
};


// export const getStudentsApi = async (page) => {
//   const token = JSON.parse(localStorage.getItem('token'));
//   console.log("dage===no===",page)
//   if (!token) return;

//   try {
//     const response = await API.get(`student/?page=${page}`, {
//       headers: {
//         "Authorization": `Bearer ${token.access}`,
//       },
//     });

//     return response.data; // Assuming the API response is in the format you provided earlier.
//   } catch (error) {
//     console.log('error fetching occurrences', error.message);
//     throw error; // Rethrow the error so it can be caught in the saga
//   }
// };

// export const getStudentsApi = async () => {
//   const token = JSON.parse(localStorage.getItem('token'));
    
//   if (!token) return;

//   try {
//       return await API.get(`student/`,{
//               headers: {
//                 "Authorization": `Bearer ${token.access}`,
//               }
//             });
//     } catch (error) {
//       console.log('error fetching occurrences', error.message);
//     }
// };

export const getSchedulesApi = async () => {
  const token = JSON.parse(localStorage.getItem('token'));
    
  if (!token) return;

  try {
      return await API.get(`schedules/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};


export const getStudentDetailsApi = async (id) => {
  const token = JSON.parse(localStorage.getItem('token'));
    
  if (!token) return;

  try {
      return await API.get(`student/${id}/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};

export const getStudentProfileApi = async (id) => {
  const token = JSON.parse(localStorage.getItem('token'));
    
  if (!token) return;

  try {
      return await API.get(`student/${id}/details/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};

export const deleteScheduleApi = async (id) => {
  const token = JSON.parse(localStorage.getItem('token'));
    
  if (!token) return;

  try {
      return await API.delete(`schedules/${id}/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};


export const getUsersApi = async () => {
  const token = JSON.parse(localStorage.getItem('token'));
    
  if (!token) return;

  try {
      return await API.get(`users/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};



export const confirmBookingApi = async ( query ) => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (!token ) return;

  try {
      return await API.post(`occurrence/`, query, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error adding schedules', error.message);
    }
};


export const completeBookingApi = async ( id ) => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (!token ) return;

  try {
      return await API.post(`occurrence/${id}/complete/`, {}, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error adding schedules', error.message);
    }
};

export const openBookingApi = async ( id ) => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (!token ) return;

  try {
      return await API.post(`occurrence/${id}/open/`, {}, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error adding schedules', error.message);
    }
};

export const fetchBookingApi = async () => {
  const token = JSON.parse(localStorage.getItem('token'));
    
  if (!token) return;

  try {
      return await API.get(`occurrence/booked/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};

export const fetchBookingCompApi = async () => {
  const token = JSON.parse(localStorage.getItem('token'));
    
  if (!token) return;

  try {
      return await API.get(`occurrence/completed/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};


export const saveTimeOffApi = async (startDate, endDate, allDay, startTime, endTime, timeoffs) => {
  const token = JSON.parse(localStorage.getItem('token'));

  // if (!token || !startTime || !endTime || !batchTime || !repeatOn || !valueRadio) return;

  const uploadData = {"start_date":startDate, "end_date":endDate ,"start_time":startTime, "end_time":endTime, "all_day":allDay}
  try {
      // const { newdata } = await API.post(`users/`,uploadData)
      return await API.post(`timeoff/`, uploadData, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error adding schedules', error.message);
    }
};


export const getTimeOffApi = async () => {
  const token = JSON.parse(localStorage.getItem('token'));
    
  if (!token) return;

  try {
      return await API.get(`timeoff/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};


export const deleteTimeOffApi = async (id) => {
  const token = JSON.parse(localStorage.getItem('token'));
    
  if (!token) return;

  try {
      return await API.delete(`timeoff/${id}/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};


export const editUserProfile = async (id, firstName, lastName, email, mobileNo) => {
  // if (!email || !password || !firstname) return;
  const token = JSON.parse(localStorage.getItem('token'));
  
  const uploadData = new FormData();
  uploadData.append('first_name', firstName);
  uploadData.append('last_name', lastName);
  uploadData.append('email', email);
  uploadData.append('mobile_no', mobileNo);

  try {
    return await API.patch(`users/${id}/`,uploadData, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error creating the user', error.message);
    }

};


export const getUserBookingOccurrences = async () => {
  const token = JSON.parse(localStorage.getItem('token'));
  try {
      return await API.get(`occurrence/user/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};


export const cancleBookingApi = async ( id ) => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (!token ) return;

  try {
      return await API.patch(`occurrence/${id}/cancle/`, {}, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error adding schedules', error.message);
    }
};


export const fetchBookingDetailApi = async ( id ) => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (!token ) return;

  try {
      return await API.get(`occurrence/${id}/?isBooked=true`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error adding schedules', error.message);
    }
};


export const editPrescriptionApi = async (id, prescription) => {
  // if (!email || !password || !firstname) return;
  const token = JSON.parse(localStorage.getItem('token'));

  try {
    return await API.patch(`occurrence/${id}/prescription/`,{'prescription':prescription}, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error creating the user', error.message);
    }

};

export const getAdminDashboard = async ( id ) => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (!token ) return;

  try {
      return await API.get(`dashboard`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error featching admin Dashboard', error.message);
    }
};

export const emailSubscribed = async (email) => {
  if (!email) return;
  const { data } = await API.post(`emailsubscribe/`,{'email': email})
  localStorage.setItem("token", JSON.stringify(data));
  return data
};


export const saveCommentApi = async (postSlug, section, commentData) => {
  // You can add your token logic here if needed
  const token = JSON.parse(localStorage.getItem('token'));
  
  if (!token) return;

  try {
    // Assuming you have a comment payload that contains the actual comment data
    return await API.post(`comments/`, {
      post_slug: postSlug,
      section,
      content: commentData,
    }, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }});
  } catch (error) {
    console.log('Error saving the comment:', error.message);
    throw error;  // This will allow the saga to catch the error
  }
};


export const getCommentApi = async (postSlug, section) => {
  // const token = JSON.parse(localStorage.getItem('token'));
    
  // if (!token) return;
  try {
      return await API.get(`comments/?postSlug=${postSlug}&section=${section}`);
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};


export const deleteCommentApi = async (id) => {
  const token = JSON.parse(localStorage.getItem('token'));
    
  if (!token) return;

  try {
      return await API.delete(`comments/${id}/`,{
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }
            });
    } catch (error) {
      console.log('error fetching occurrences', error.message);
    }
};


// export const saveReplyApi = async (replyData) => {
//   try {
//     return await API.post('comments/reply', replyData); // Adjust the URL if needed
//   } catch (error) {
//     console.error('Error saving reply:', error);
//     throw error;
//   }
// };



export const saveReplyApi = async (replyData) => {
  // You can add your token logic here if needed
  const token = JSON.parse(localStorage.getItem('token'));
  
  if (!token) return;

  try {
    // Assuming you have a comment payload that contains the actual comment data
    return await API.post(`comments/reply/`, replyData, {
              headers: {
                "Authorization": `Bearer ${token.access}`,
              }});
  } catch (error) {
    console.log('Error saving the reply:', error.message);
    throw error;  // This will allow the saga to catch the error
  }
};