// import axios from "axios";

// export default axios.create({
//   // baseURL: "http://127.0.0.1:8000/",
//   baseURL: "https://api.earngenix.com/",
//   headers: {
//     "Content-type": "application/json"
//   }
// });



import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json"
  }
});
