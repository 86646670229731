import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import DrawerAppBar from './Header';
import { emailSignInStart, getSnapshotFromUserAuth } from "../../store/user/action";
import { selectCurrentUser, selectSigninError } from '../../store/user/selector';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import API from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  submit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(3),
  },
  separator: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
  },
  line: {
    flexGrow: 1,
    height: 1,
    backgroundColor: '#ccc',
  },
  centeredGoogleButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));
export default function SignIn() {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  
  const currentUser = useSelector(selectCurrentUser);
  const signinError = useSelector(selectSigninError);
  const dispatch = useDispatch();

  const loginClicked = (e) => {
    e.preventDefault();
    dispatch(emailSignInStart(username, password));
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const { data } = await API.post('/auth-return/', {
        token: response.credential,
      });

      if (data.status === 'success') {
        const redirectUrl = localStorage.getItem('redirectUrl') || '/';
        localStorage.setItem("token", JSON.stringify(data));
        localStorage.removeItem('redirectUrl');
        dispatch(getSnapshotFromUserAuth());
        navigate(redirectUrl);
      } else {
        console.error('Error logging in with Google:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleGoogleError = (error) => {
    console.error('Google Sign-In Error:', error);
  };

  useEffect(() => {
    if (currentUser) {
      const redirectUrl = localStorage.getItem('redirectUrl') || '/';
      localStorage.removeItem('redirectUrl');
      navigate(redirectUrl);
    } else if (signinError) {
      alert("Check your email and password");
    }
  }, [currentUser, signinError, navigate]);

  return (
    <GoogleOAuthProvider clientId="182767965221-25lq6c03vknceephnqbbjgm8r8dh7h2q.apps.googleusercontent.com">
      <Container component="main" maxWidth="xs">
        <DrawerAppBar />
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <FollowTheSignsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={username}
              onChange={(evt) => setUsername(evt.target.value)}
              className={classes.textField}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
              className={classes.textField}
            />
            <Grid container justifyContent="flex-end" className={classes.textField}>
              <Grid item>
                Don't have an account?
                <Link href="/signup" variant="body2">
                  {" Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={loginClicked}
            >
              Sign In
            </Button>
            
            <div className={classes.separator}>
              <div className={classes.line}></div>
              <Typography variant="body2" style={{ margin: '0 8px', color: '#888' }}>OR</Typography>
              <div className={classes.line}></div>
            </div>

            <div className={classes.centeredGoogleButton}>
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleError}
                theme='filled_blue'
                shape='pill'
                size='large'
              />
            </div>
          </form>
        </div>
      </Container>
    </GoogleOAuthProvider>
  );
}
