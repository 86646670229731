import { COMMENT_ACTION_TYPES } from './types';

import { createAction } from '../../utils/reducer.utils';

export const fetchCommentStart = (postSlug, section) =>
  createAction(COMMENT_ACTION_TYPES.FETCH_COMMENT_START, {postSlug, section});
export const fetchCommentSuccess = (CommentArray) =>
  createAction(COMMENT_ACTION_TYPES.FETCH_COMMENT_SUCCESS, CommentArray);
export const fetchCommentFailed = (error) =>
  createAction(COMMENT_ACTION_TYPES.FETCH_COMMENT_FAILED, error);

export const saveCommentStart = (postSlug, section, commentData) =>
  createAction(COMMENT_ACTION_TYPES.SAVE_COMMENT_START, { postSlug, section, commentData});
export const saveCommentSuccess = (CommentArray) =>
  createAction(COMMENT_ACTION_TYPES.SAVE_COMMENT_SUCCESS, CommentArray);
export const saveCommentFailed = (error) =>
  createAction(COMMENT_ACTION_TYPES.SAVE_COMMENT_FAILED, error);

export const deleteCommentStart = ( id, CommentArray) =>
  createAction(COMMENT_ACTION_TYPES.DELETE_COMMENT_START, { id, CommentArray });
export const deleteCommentSuccess = (CommentArray) =>
  createAction(COMMENT_ACTION_TYPES.DELETE_COMMENT_SUCCESS, CommentArray);
export const deleteCommentFailed = (error) =>
  createAction(COMMENT_ACTION_TYPES.DELETE_COMMENT_FAILED, error);


export const saveReplyStart = (replyData) => ({
  type: COMMENT_ACTION_TYPES.SAVE_REPLY_START,
  payload: replyData,
});

export const saveReplySuccess = (reply) => ({
  type: COMMENT_ACTION_TYPES.SAVE_REPLY_SUCCESS,
  payload: reply,
});

export const saveReplyFailed = (error) => ({
  type: COMMENT_ACTION_TYPES.SAVE_REPLY_FAILED,
  payload: error,
});
